import { api, config } from "../../../Helpers";
const { API_URL } = config;

export const GET_EDITION = "GET_EDITION";
export const GET_EDITION_LOADING = "GET_EDITION_LOADING";
export const GET_EDITION_ERROR = "GET_EDITION_ERROR";

export function getEdition(id) {
  return dispatch => {
    dispatch({ type: GET_EDITION_LOADING, payload: true });

    api
      .get(API_URL + `/editions/pages/${id}`)
      .set(
        "x-auth-token",
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwidXNlcl9yb2xlX2lkIjoxLCJpYXQiOjE1NzI4Mzc3Nzd9.lkCWpzusaFZHqv3kFdhg0YBdZtzNBzl9ZuCLk2G8Cmw"
      )
      .set("Content-Type", "application/json")
      .then(res => {
        if (res.body.success) {
          dispatch({
            type: GET_EDITION,
            payload: res.body.data
          });
        } else {
          console.log(res.body.message);
        }

        dispatch({ type: GET_EDITION_LOADING, payload: false });
      })
      .catch(error => {
        dispatch({ type: GET_EDITION_LOADING, payload: false });
        dispatch({
          type: GET_EDITION_ERROR,
          payload: error.response.body.message
        });
      });
  };
}
