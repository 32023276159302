import * as Actions from "../actions";
import update from "immutability-helper";

const initialState = {
  editionPageList: "null",
  editionLoading: false,
  editionError: ""
};

const edition = function(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_EDITION: {
      return update(state, {
        editionPageList: {
          $set: action.payload
        }
      });
    }
    case Actions.GET_EDITION_LOADING: {
      return update(state, {
        editionLoading: {
          $set: action.payload
        }
      });
    }
    case Actions.GET_EDITION_ERROR: {
      return update(state, {
        editionError: {
          $set: action.payload
        }
      });
    }
    default: {
      return state;
    }
  }
};

export default edition;
