import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { _ } from "../../../Helpers";
import * as Actions from "../actions";
import { connect } from "react-redux";
import Header from "../../Common/header";
import Splash from "../../Splash";
import PageView from "../../Page/containers/PageView";
import prevButton from "../../../../assets/images/left-arrow-dark.png";
import nextButton from "../../../../assets/images/right-arrow-dark.png";
import Style from "style-it";
import LoadingPage from "../../LoadingPage";
import buddyLogo from "../../../../assets/images/buddy-logo.png";
import edition from "../reducers";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "homepage",
      themeColor: "",
      navActivePage: "",
      business: "",
      openMenu: false,
      menuNavActive: "pages",
      activePage: "",
      isNavOpen: false,
      animationSlide: "nextSlide",
      edition: "",
      editionErrorMsg: ""
    };

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    // this.prevPage = this.prevPage.bind(this);
    // this.nextPage = this.nextPage.bind(this);
  }

  componentDidMount() {
    this.props.getEdition(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    const { editionLoading, editionPageList, editionError } = this.props;
    if (prevProps.editionLoading !== editionLoading && editionLoading) {
    }
    if (prevProps.editionPageList !== editionPageList && editionPageList) {
      this.setState({
        business: editionPageList.business,
        pages: editionPageList.pages
      });
      this.changeIcon();
    }
  }

  changeIcon() {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = this.props.editionPageList.business.logo_url
      ? this.props.editionPageList.business.logo_url
      : buddyLogo;
    document.getElementsByTagName("head")[0].appendChild(link);
    document.title = this.props.editionPageList.business.name
      ? this.props.editionPageList.business.name
      : "React App";
  }

  handlePageChange(val) {
    this.setState({
      page: val.page,
      html: val.html,
      css: val.css,
      themeColor: val.primary_color,
      activePage: val.page,
      animationSlide: "nextSlide"
    });
  }

  handlePageChangeByNav(val, i) {
    this.setState({
      page: i,
      html: val.html,
      css: val.css,
      themeColor: this.state.business.primary_hex_color,
      activePage: i,
      isNavOpen: !this.state.isNavOpen,
      openMenu: !this.state.openMenu,
      animationSlide: "nextSlide"
    });
  }

  handleMenu(val) {
    this.setState({ openMenu: val, isNavOpen: !this.state.isNavOpen });
  }

  handleNav(value) {
    this.setState({ menuNavActive: value });
  }

  clickNext() {
    const { business, activePage, pages } = this.state;

    if (activePage < pages.length) {
      this.setState({
        page: activePage + 1,
        html: pages[activePage + 1].html,
        css: pages[activePage + 1].css,
        themeColor: business.primary_hex_color,
        activePage: activePage + 1,
        animationSlide: "nextSlide"
      });
    }
  }

  clickPrev() {
    const { business, activePage, pages } = this.state;

    if (activePage !== pages.length) {
      this.setState({
        page: activePage - 1,
        html: pages[activePage - 1].html,
        css: pages[activePage - 1].css,
        themeColor: business.primary_hex_color,
        activePage: activePage - 1,
        animationSlide: "prevSlide"
      });
    }
  }

  render() {
    const {
      page,
      business,
      pages,
      html,
      css,
      openMenu,
      menuNavActive,
      themeColor,
      isNavOpen,
      animationSlide,
      editionErrorMsg
    } = this.state;
    const { editionPageList, editionLoading } = this.props;
    return (
      <>
        <Style>
          {`
            #menu-container{
              min-height:100vh;
              width:100%;
              background: ${business.background_hex_color};
              position: absolute;
              z-index: 10;
              top: 0;
              left:0;
              height: auto;
              -webkit-animation-name: transform-nav;
              -webkit-animation-duration: 1s; 
              animation-name: transform-nav;
              animation-duration: 1s;
              transform: translateY(0);
            }

            @-webkit-keyframes transform-nav {
                from {transform: translateY(-100vh);}
                to {transform: translateY(0);}
            }
              
            @keyframes transform-nav {
              from {transform: translateY(-100vh);}
              to {transform: translateY(0);}
            }

            @media(max-width:600px){
              #nav-pages-container{
                padding: 30px 0px!important;
              }
              #nav-page-container{
                width:300px!important;
                height:280px!important;
              }
            }

            #nav-pages-container{
              display: flex;
              position: relative;
              flex-wrap: wrap;
              justify-content: center;
              padding: 2em 3em;
            }
          
            #nav-page-container{
              position: relative;
              width: 420px;
              height: 300px;
              margin: 5px;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #f1f1f1;
              transition: .5s ease-in-out;
            }
            #nav-page-container:hover{
              background: transparent;
            }
            #nav-page-container span{
                color:${business.primary_hex_color};
                font-size: 60px;
                width:100%;
                text-align: center;
            }
            #nav-page-container div{
              width: 100%;
              word-wrap: break-word;
              display: flex;
            }
            #nav-img-placeholder{
              width:100%;
              height: 100%;
              object-fit: cover;
            }
      
            #menu-tabs-container{
              height: 72px;
              background:  ${business.background_hex_color};
              display: flex;
              position: relative;
              width:100%;
              align-items: center;
              border-bottom:3px solid ${business.primary_hex_color};
            }
          
            #menu-tabs-container #menu-nav-item{
              color: white!important;
              cursor: pointer!important;
              font-size: 16px;
              padding: 0 30px;
              cursor: pointer;
              height: 100%;
              display: flex;
              position: relative;
              align-items: center;
              justify-content: center;
              color: white;
              min-width:150px;
              font-family: arial!important;
          
            }
          
            #menu-tabs-container .span-active{
              background: ${business.primary_hex_color};
            }
          
            #menu-tab-close-btn{
              font-size: 36px;
              position: absolute;
              z-index:2;
              right: 30px;
              display: flex;
              align-items: center;
              height: 60px;
              top: 0;
              cursor:pointer;
              color: white;
            }

            .${animationSlide}${page}{
              -webkit-animation-name: ${animationSlide}${page}; 
              -webkit-animation-duration: .8s; 
              animation-name: ${animationSlide}${page};
              animation-duration: .8s;
              transform: translateX(0);
              scroll-behavior: smooth;
            }

            @-webkit-keyframes nextSlide${page} {
              from {margin-left:-100%; opacity:0}
              to {margin-left:0; opacity: 1}
            }
  
            @keyframes nextSlide${page} {
              from {margin-left:-100%; opacity:0}
              to {margin-left:0; opacity: 1}
            }

            @-webkit-keyframes prevSlide${page} {
              0% {margin-left:100%; opacity:0}
              100% {margin-left:0 opacity:1}
            }

            @keyframes prevSlide${page} {
              0% {margin-left:100%; opacity:0}
              100% {margin-left:0; opacity:1}
            }
          h1{
            font-size:300px;
          }

              `}
        </Style>

        {!editionLoading && (
          <div className="pos-rel">
            {!editionLoading && (
              <Header
                handlePageChange={this.handlePageChange}
                page={page}
                editionData={editionPageList}
                business={business}
                pages={pages}
                handleMenu={this.handleMenu}
                openMenu={openMenu}
              />
            )}
            {openMenu && (
              <div id="menu-container">
                <div id="menu-tabs-container">
                  <span
                    onClick={() => this.handleNav("pages")}
                    id="menu-nav-item"
                    className={menuNavActive === "pages" ? "span-active" : ""}
                  >
                    PAGES
                  </span>

                  {/* <span
                  onClick={() => this.handleNav("editions")}
                  id="menu-nav-item"
                  className={menuNavActive === "editions" ? "span-active" : ""}
                >
                  Editions
                </span> */}
                </div>
                <span
                  id="menu-tab-close-btn"
                  onClick={() => this.handleMenu(false)}
                >
                  &times;
                </span>
                <div id="nav-pages-container">
                  {pages &&
                    pages.map((key, i) => {
                      return (
                        <div
                          key={i}
                          id="nav-page-container"
                          onClick={() =>
                            this.handlePageChangeByNav(pages[i], i)
                          }
                        >
                          <div id="nav-page-title-container">
                            <span>{pages[i].title}</span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            {(this.props.editionError && (
              <Splash editionErrorMsg={this.props.editionError} />
            )) ||
              (page === "homepage" && (
                <Splash business={business} pages={pages} />
              )) || (
                <div
                  id={isNavOpen ? "display-none" : ""}
                  className={`${animationSlide}${page}`}
                >
                  <div id="page-view-container">
                    <PageView html={html} css={css} themeColor={themeColor} />
                  </div>
                </div>
              )}
            {page !== "homepage" && (
              <div id="page-controllers">
                {pages && (
                  <>
                    {page + 1 !== pages.length && (
                      <img
                        src={nextButton}
                        id="controller-img"
                        onClick={() => this.clickNext()}
                      />
                    )}
                    {page - 1 >= 0 && (
                      <img
                        src={prevButton}
                        id="controller-img"
                        onClick={() => this.clickPrev()}
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        )}
        <>{editionLoading === true && <LoadingPage />}</>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEdition: Actions.getEdition
    },
    dispatch
  );
}

const mapStateToProps = ({ edition }) => {
  const { editionPageList, editionLoading, editionError } = edition;
  return {
    editionPageList,
    editionLoading,
    editionError
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
