import React from "react";
import { withRouter } from "react-router-dom";
import Style from "style-it";
import "bootstrap/dist/css/bootstrap.min.css";
import dompurify from "dompurify";

dompurify.addHook("afterSanitizeAttributes", function(node) {
  if ("target" in node) {
    node.setAttribute("target", "_blank");
  }

  if (
    !node.hasAttribute("target") &&
    (node.hasAttribute("xlink:href") || node.hasAttribute("href"))
  ) {
    node.setAttribute("xlink:show", "new");
  }
});

class PageView extends React.Component {
  render() {
    const { html, css, themeColor } = this.props;
    const sanitizer = dompurify.sanitize;

    var config = { ADD_TAGS: ["iframe"], KEEP_CONTENT: true };

    return (
      <>
        {html && (
          <>
            <Style>
              {`
               ${css}    
              .link, .link:hover, .link:visited{
                text-decoration: none;
              }`}
            </Style>
            <div
              className="page_container"
              dangerouslySetInnerHTML={{ __html: sanitizer(html, config) }}
            />
          </>
        )}
      </>
    );
  }
}

export default withRouter(PageView);
