import React, { Component } from "react";
import Style from "style-it";

class Splash extends Component {
  render() {
    const { business, pages, editionErrorMsg } = this.props;
    const new_business = business || {};
    const new_pages = pages || [];
    var background_hex_color =
      Object.keys(new_business).length == 0
        ? "#f1f1f1"
        : new_business.background_hex_color;
    return Style.it(
      `
        .splash-section{
            min-height:100vh;
            background: ${background_hex_color};
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .splash-logo-text-container{
            position: relative;
            display: flex;
            justify-content: center;
            align-items:center;
        }
        @media(min-width:992px) and (max-width: 1600px){
          .splash-logo{
          height:500px;
          width: auto;
        }
      }

      @media(min-width:1601px){
        .splash-logo{
        width: 680px;
      }
    }


        @media(max-width:991px){
          .splash-logo{
            height:auto;
            width: 60%;
        }
        }

        @media(height: 600px){
          .splash-logo{
            height:400px;
            width: auto;
          }
        }

        @media(max-height: 400px){
          .splash-logo{
            height:250px;
            width: auto;
          }
        }
       #not-exist-container{
         height:100vh;
         width:100vw;
         display: flex;
         justify-content: center;
         align-items: center;
         align-content: center;
         padding: 4em;
         background: #f1f1f1;
         position: absolute;
         z-index: 5;
       }
       #not-exist-container span{
        font-size: calc(28px + (60 - 28) * ((100vw - 300px) / (1600 - 300)));
        width:80%;
       }
      `,

      <div>
        {((Object.keys(new_business).length == 0 || new_pages.length == 0) && (
          <div id="not-exist-container">
            <span style={{ textAlign: "center" }}>
              Oops! Sorry Buddy, the page you're looking for does not exist.
            </span>
          </div>
        )) || (
          <section className="splash-section">
            <div className="splash-logo-text-container">
              <img src={new_business.logo_url} className="splash-logo"></img>
            </div>
          </section>
        )}
      </div>
    );
  }
}

export default Splash;
