import React, { Component } from "react";
import Style from "style-it";

class LoadingPage extends Component {
  render() {
    return Style.it(
      `
        #loading-page-container{
            height: 100vh;
            width: 100vw;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
        }
        .loading-bro {
            margin: 50px auto;
            width: 150px;
        }
      
        #load {
            width: 150px;
            animation: loading 3s linear infinite;
        
          }
        #load #loading-inner {
            stroke-dashoffset: 0;
            stroke-dasharray: 300;
            stroke-width: 10;
            stroke-miterlimit: 10;
            stroke-linecap: round;
            animation: loading-circle 2s linear infinite;
            stroke: #F7CA19;
            fill: transparent;
        }
      
        @keyframes loading {
                0% { 
                transform: rotate(0); 
            }
                100% { 
                transform: rotate(360deg);
            }
        }
        @keyframes loading-circle {
              0% { 
            stroke-dashoffset: 0
          }
              100% { 
            stroke-dashoffset: -600;
          }
      }
      `,

      <div id="loading-page-container">
        <div className="loading-bro">
          <svg id="load" x="0px" y="0px" viewBox="0 0 150 150">
            <circle id="loading-inner" cx="75" cy="75" r="60" />
          </svg>
        </div>
      </div>
    );
  }
}

export default LoadingPage;
