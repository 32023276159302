import { applyMiddleware, compose, createStore } from "redux";
import reducers from "./Reducers";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";

const log = createLogger({ diff: true, collapsed: true });

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

const store = createStore(reducers, enhancer);

export default store;
