import React from "react";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";

import Home from "../app/modules/Home/containers";
import Splash from "../app/modules/Splash";

export default () => {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={Splash} />
        <Route exact path="/:id" component={Home} />
        <Redirect to="/" />
      </Switch>
    </HashRouter>
  );
};
