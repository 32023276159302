import React, { Component } from "react";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav } from "reactstrap";
import Style from "style-it";
import logo from "../../../assets/images/navbar-logo.png";

class Header extends Component {
  state = {
    isOpen: false,
    openMenu: false
  };


  componentDidMount() {
    const slider = document.querySelector(".draggable-nav");
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener("mousedown", e => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener("mouseleave", () => {
      setTimeout(function() {
        isDown = false;
        slider.classList.remove("active");
      }, 50);
    });
    slider.addEventListener("mouseup", () => {
      setTimeout(function() {
        isDown = false;
        slider.classList.remove("active");
      }, 50);
    });
    slider.addEventListener("mousemove", e => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });
  }

  toggle() {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }

  changePage(html, css, page) {
    this.props.handlePageChange({
      page: page,
      html: html,
      css: css,
      primary_color: this.props.business.primary_hex_color
    });
    if (this.state.isOpen === true) {
      this.setState({ isOpen: !this.state.isOpen });
    }
  }

  showMenu(value) {
    this.props.handleMenu(value);
  }

  render() {
    const { isOpen } = this.state;
    const { business, pages, page, openMenu } = this.props;

    return Style.it(
      `
        .navbar{
            padding: 0 1rem;
        }
        .navbar-bg {
         background: ${business.background_hex_color};
         position: fixed;
         width: 100%;
         top:0;
         z-index: 3;
         border-bottom:3px solid ${business.primary_hex_color}; 

        }
        .navbar-logo-size{
            max-width:60px;
        }
        .navbar-ul{
            width:100%;
            position: relative;
            height: 72px;
        }
        .nav-item-width{
            width: fit-content;
            cursor: pointer;
            display: flex;
            justify-content: center;
            position: relative;
            align-items: center;
            color: ${business.secondary_hex_color};
            z-index: 2;
            padding: 0 60px;
            min-width:150px;
        }
        .nav-item-width:hover{
            text-decoration:none!important;
        }
        .nav-item-width a, .nav-item-width a:hover,  .nav-item-width a:focus{
            color: ${business.secondary_hex_color}!important;
            font-size: 16px!important;
            cursor: pointer!important;
            padding: 0!important;
            align-items: center!important;
            justify-content: center!important;
            display: flex!important;
            text-decoration: none!important;
            font-family: arial!important; 
        },
           #nav-font{
            color: ${business.secondary_hex_color}!important;
            font-size: 16px!important;
            cursor: pointer!important;
            align-items: center!important;
            justify-content: center!important;
            display: flex!important;
            text-decoration: none!important;
            font-family: arial!important; 
           }
          .navbar-ul .nav-item-width:focus {
            background-color: ${business.primary_hex_color};
          }
          
          .nav-item-active{
              background: ${business.primary_hex_color};
          }

       
        @media(max-width:1199px){
            .navbar-ul{
                flex-direction: column;
                flex-wrap: nowrap;
                height:auto;
                max-height: 400px;
                overflow-y: scroll;
            }
            .nav-item-width{
                width:100%;
                min-height: 40px;
            }

              
              .navbar-bg {
                  padding: 0 0;
              }
              .navbar-logo-size {
                  margin: 0 15px;
              }

              .navbar-toggler {
                  border: 1.5px solid ${business.secondary_hex_color};
                  margin: 15px;
              }

              .navbar-dark .navbar-toggler-icon {
                  background: transparent;
                  position: relative;
              }
            .navbar-dark .navbar-toggler-icon:before{
                color: ${business.secondary_hex_color};
            }
            
        }

       @media(min-width:1200px){
        #navbarbrand-absolute{
          height: 72px;
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          min-width: 160px;
          justify-content: center;
          display: flex;
          padding: 0 30px;
          align-items: center;
          background: ${business.background_hex_color};
          z-index: 3;
        }

        .nav-item-width:first-child{
          margin-left:140px;
        }
      }
      `,

      <div>
        <Navbar
          id="navbar"
          expand="xl"
          dark
          className="navbar-bg draggable-nav"
        >
          <NavbarBrand
            onClick={() => this.showMenu(!openMenu)}
            id="navbarbrand-absolute"
          >
            <img src={business.logo_url} className="navbar-logo-size" />
          </NavbarBrand>
          <NavbarToggler onClick={() => this.toggle()} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="navbar-ul ml-auto ">
              {pages &&
                pages.map((key, i) => {
                  return (
                    <div
                      key={i}
                      className={
                        page === i
                          ? "nav-item-width nav-item-active"
                          : "nav-item-width"
                      }
                      id="nav-font"
                      onClick={() =>
                        this.changePage(pages[i].html, pages[i].css, i)
                      }
                    >
                      {pages[i].title}
                    </div>
                  );
                })}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default Header;

/** TRANSFORM TRANSLATE NAVBAR **/

// .navbar-logo-size {
//   width: 55px;
//   transform: rotate(90deg);
// }

//  .navbar-bg {
//   background: #19132D;
//   position: fixed;
//   width: 100%;
//   top: 0;
//   z-index: 3;
//   border-bottom: 3px solid undefined;
//   transform-origin: left top;
//   transform: rotate(-90deg) translateX(-100%);
//   flex-direction: row-reverse;
// }
