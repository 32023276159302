import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import store from "./app/Store";
import history from "./app/History";
import "bootstrap/dist/css/bootstrap.min.css";

import App from "./app/App";
import serviceWorker from "./serviceWorker";

const rootElement = document.getElementById("root");

const appRender = async () => {
  render(
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>,
    rootElement
  );
};
appRender();
serviceWorker();
